import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="issue-tags-form"
export default class extends Controller {
  static targets = [ "list", "select", "form" ]
  connect() {
    this.updateFormVisibility();
  }

  updateFormVisibility() {
    if (this.selectTarget.options.length === 0) {
      this.formTarget.style.display = "none";
    } else {
      this.formTarget.style.display = "flex";
    }
  }

  addTag(e) {
    const tagId = this.selectTarget.value

    if (tagId) {
      // tag color is stored in data-color attribute
      const tagColor = this.selectTarget.selectedOptions[0].dataset.color

      // add it to the list as li and a hidden field
      this.listTarget.insertAdjacentHTML("beforeend", `
        <li>
            <span class="issue-tag" style="background-color: ${tagColor}">
                ${this.selectTarget.selectedOptions[0].text}
                <i class="fas fa-times cursor-pointer" data-action="click->issue-tags-form#removeTag"></i>
            </span>
            <input type="hidden" name="issue[tag_ids][]" value="${tagId}">
        </li>
        `)

      // remove it from the select
      this.selectTarget.selectedOptions[0].remove()
      this.updateFormVisibility()
    }

    e.preventDefault()
  }

  removeTag(e) {
    // get data-id attribute of e.target
    const tagId = e.target.parentElement.dataset.id;

    const tagColor = e.target.closest(".issue-tag").style.backgroundColor
    const tagName = e.target.closest(".issue-tag").innerText

    // add it back to the select
    this.selectTarget.insertAdjacentHTML("beforeend", `
      <option value="${tagId}" data-color="${tagColor}" style="background-color: ${tagColor}; color: #333;">${tagName}</option>
    `)

    // remove it from the list
    e.target.closest(".issue-tag").parentElement.remove()
    this.updateFormVisibility()
    e.preventDefault()
  }
}
