import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="new-survey-question-form"
export default class extends Controller {

  static targets = [ "submitButton", "questionTypeSelect" ]

  connect() {
    console.log("Hello, Stimulus!", this.element)

    // if questionTypeSelect changes to single_choice or multiple_choice, change submitButton text to "Next"
    // otherwise, change submitButton text to "Create"

    let originalActionUrl = this.element.action

    this.questionTypeSelectTarget.addEventListener('change', (event) => {
      console.log(event.target.value)
      if (event.target.value == "single_choice" || event.target.value == "multiple_choice") {
        this.submitButtonTarget.innerText = "Next"
        this.submitButtonTarget.value = "Next"
        this.element.action = this.data.get("next-action-url")
        this.element.method = "get"
      } else {
        this.submitButtonTarget.innerText = "Create"
        this.submitButtonTarget.value = "Create"
        this.element.action = originalActionUrl
        this.element.method = "post"
      }
    })

  }
}
